import { Merchant, fetchMerchantById } from '@mom/services'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useLocationState } from './useLocationState'

export const useMerchantState = () => {
  const [merchant, setMerchant] = useLocationState<Merchant>()
  const { merchantId } = useParams()

  useEffect(() => {
    if (merchantId && merchantId !== merchant?.Id?.toString()) {
      fetchMerchantById(Number.parseInt(merchantId)).then((merchant) => {
        setMerchant(merchant)
      })
    }
  }, [merchantId])

  return {
    merchantId,
    merchant,
  }
}
