import { CentredLayout, Page } from '@mom/components'
import { useMerchantState } from 'lib/useMerchantState'
import { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { MerchantTabs } from 'components/Merchant/MerchantTabs'
import { OfferEditSidebar } from 'components/Offer/OfferEditSidebar'
import { OfferForm, OfferFormData } from 'components/Offer/OfferForm'

export const AddNewOffer = () => {
  const { discountType } = useParams()

  const form = useForm<OfferFormData>({
    defaultValues: {
      discountType,
      offerName: '',
      categoryType: [],
    },
    mode: 'onChange',
  })

  const { formState, handleSubmit, trigger } = form
  const { dirtyFields, errors, isValid } = formState
  const { merchant } = useMerchantState()
  const [saveStatus, setSaveStatus] = useState('D')

  const onSubmit: SubmitHandler<OfferFormData> = useCallback(
    async (data: OfferFormData) => {
      await trigger()
      if (isValid) {
        console.debug(data)
      }
    },
    [form],
  )

  return (
    <Page
      renderToolbar={() => <MerchantTabs merchant={merchant} selectedIndex={2} />}
      renderSidebar={() => (
        <OfferEditSidebar
          isNewOffer
          status={saveStatus}
          onChangeStatus={(status) => setSaveStatus(status)}
          onSave={() => handleSubmit(onSubmit)()}
          tasks={[]}
        />
      )}
      title={merchant?.DoingBusinessAs?.Name ?? ''}
    >
      <CentredLayout allowForSidebar>
        {merchant && <OfferForm draft={saveStatus === 'D'} form={form} onSubmit={onSubmit} />}
      </CentredLayout>
    </Page>
  )
}
