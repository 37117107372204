import { CentredLayout, MerchantSideBar, Page } from '@mom/components'
import Typography from '@mui/material/Typography'
import { useMerchantState } from 'lib/useMerchantState'
import { FormattedMessage } from 'react-intl'

import { AddSingleLocationForm } from 'components/Locations/AddSingleLocationForm'
import { MerchantTabs } from 'components/Merchant/MerchantTabs'

export const AddNewLocation = () => {
  const { merchant } = useMerchantState()

  return (
    <Page
      renderSidebar={() => merchant && <MerchantSideBar merchant={merchant} />}
      renderToolbar={() => <MerchantTabs merchant={merchant} selectedIndex={1} />}
      title={merchant?.DoingBusinessAs?.Name ?? ''}
    >
      <CentredLayout allowForSidebar>
        <Typography variant='h3' ml={-2} mt={-2} pb={4}>
          <FormattedMessage id='pages.locationAdd.title' />
        </Typography>

        {merchant && <AddSingleLocationForm merchant={merchant} />}
      </CentredLayout>
    </Page>
  )
}
