import { Ability, AbilityBuilder, AbilityClass, ForcedSubject } from '@casl/ability'
import { User } from '@mom/services'

export enum Actions {
  ADD = 'add',
  EDIT = 'edit',
  LIST = 'list',
}

export enum Subjects {
  ALL = 'all',
  CORPORATIONS = 'corporations',
  DBANAMES = 'dbanames',
  LOCATION = 'location',
  MERCHANT = 'merchant',
  OFFER = 'offer',
}

const actions = Object.values(Actions)
const subjects = Object.values(Subjects)

type AppAbilities = [
  typeof actions[number],
  typeof subjects[number] | ForcedSubject<Exclude<typeof subjects[number], 'all'>>,
]
export type AppAbility = Ability<AppAbilities>
export const AppAbility = Ability as AbilityClass<AppAbility>

type DefinePermissions = (user: User, builder: AbilityBuilder<AppAbility>) => void
type Roles = 'none' | 'user' | 'admin'

const rolePermissions: Record<Roles, DefinePermissions> = {
  none (user, { cannot }) {
    cannot(Actions.ADD, Subjects.ALL)
    cannot(Actions.LIST, Subjects.ALL)
    cannot(Actions.EDIT, Subjects.ALL)
  },
  user (user, { can }) {
    can(Actions.LIST, Subjects.MERCHANT)
    can(Actions.LIST, Subjects.LOCATION)
    can(Actions.LIST, Subjects.OFFER)
  },
  admin (user, { can }) {
    can(Actions.ADD, Subjects.ALL)
    can(Actions.LIST, Subjects.ALL)
    can(Actions.EDIT, Subjects.ALL)
  },
}

export function defineAbilityFor (user?: User): AppAbility {
  const builder = new AbilityBuilder<AppAbility>(Ability)

  if (user && typeof rolePermissions[user.role as Roles] === 'function') {
    rolePermissions[user.role as Roles](user, builder)
  } else {
    rolePermissions.none({} as User, builder)
  }

  const ability = builder.build()
  ability.can = ability.can.bind(ability)
  ability.cannot = ability.cannot.bind(ability)

  return ability
}
