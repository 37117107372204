import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

export const DashboardNotificationList = () => {
  const [draftMerchant, setDraftMerchant] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('merchant.stage_basics') || localStorage.getItem('merchant.all')) {
      setDraftMerchant(true)
    }
  }, [])

  return (
    <Stack maxWidth={500}>
      {draftMerchant && (
        <Alert severity='info'>
          <FormattedMessage
            id='pages.dashboard.notifications.resumeMerchant'
            values={{ Link: (chunks) => <Link to='/merchant/add?resume=true'>{chunks}</Link> }}
          />
        </Alert>
      )}
    </Stack>
  )
}
