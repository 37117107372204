import { OfferLinkCard, Page } from '@mom/components'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useMerchantState } from 'lib/useMerchantState'
import { useNavigate } from 'react-router-dom'

import { MerchantTabs } from 'components/Merchant/MerchantTabs'

export const SelectDiscountType = () => {
  const { merchant, merchantId } = useMerchantState()
  const navigate = useNavigate()

  if (!merchantId) {
    navigate('/')
  }

  return (
    <Page
      renderToolbar={() => <MerchantTabs merchant={merchant} selectedIndex={2} />}
      title={merchant?.DoingBusinessAs?.Name ?? ''}
    >
      {merchantId && (
        <Grid container display='flex' direction='row'>
          <Grid item xs={12} lg={8}>
            <Typography variant='h4'>Choose an offer</Typography>
          </Grid>
          <Grid item lg={4} />
          <Grid item xs={12} lg={4} pb={2}>
            <OfferLinkCard
              label='% off'
              description='50% off your total bill'
              discountType='%OFF'
              imageUrl='/offer.png'
              merchantId={merchantId}
            />
          </Grid>
          <Grid item xs={12} lg={4} pb={2}>
            <OfferLinkCard
              label='BOGO'
              description='Buy one pizza get one free'
              discountType='BOGO'
              imageUrl='/offer.png'
              merchantId={merchantId}
            />
          </Grid>
          <Grid item xs={12} lg={4} pb={2}>
            <OfferLinkCard
              label='BOGO'
              description='Buy one menu item get one free'
              discountType='BOGO'
              imageUrl='/offer.png'
              merchantId={merchantId}
            />
          </Grid>
          <Grid item xs={12} lg={4} pb={2}>
            <OfferLinkCard
              label='Cash back'
              description='$10 for every $100 you spend'
              discountType='CBCK'
              imageUrl='/offer.png'
              merchantId={merchantId}
            />
          </Grid>
          <Grid item xs={12} lg={4} pb={2}>
            <OfferLinkCard
              label='Cash back'
              description='$10 for every $100 you spend'
              discountType='CBCK'
              imageUrl='/offer.png'
              merchantId={merchantId}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  )
}
