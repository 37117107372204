import { faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  DataGrid,
  MerchantDetailsCard,
  MerchantStatusCard,
  Page,
  TextField,
} from '@mom/components'
import {
  MerchantSearchRequest,
  MerchantSearchResult,
  fetchMerchants,
  useFetch,
} from '@mom/services'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { Actions, Subjects } from 'lib/ability'
import { Can } from 'lib/useUserPermissions'
import React, { KeyboardEvent, useMemo, useState } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

export const MerchantsList = () => {
  const [search, setSearch] = useState('')
  const [query, setQuery] = useState('')
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [rowCount, setRowCount] = useState(0)

  const merchantFetcher = useMemo(
    () => async () => {
      const request: MerchantSearchRequest = {
        limit: pageSize,
      }

      if (page > 0) {
        request.offset = page * pageSize
      }

      if (search?.length > 0) {
        request.name = search
      }

      const response = await fetchMerchants(request)
      setRowCount(response.Meta.Total)
      return response.Result
    },
    [pageSize, page, query],
  )
  const { data, fetching, error } = useFetch(merchantFetcher)

  const intl = useIntl()
  const navigate = useNavigate()

  const addNewMerchant = () => navigate('/merchant/add')

  const dbaNameValueGetter = (params: GridValueGetterParams<unknown, MerchantSearchResult>) =>
    params.row.DoingBusinessAs?.Name

  const handleSearchKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setQuery(search)
    }
  }

  const columns: GridColDef<MerchantSearchResult>[] = [
    {
      field: 'Merchant',
      headerName: intl.formatMessage({ id: 'dataGrids.merchants.merchant' }),
      renderCell: (params) => <MerchantDetailsCard merchant={params.row} />,
      valueGetter: dbaNameValueGetter,
      flex: 2,
    },
    {
      field: 'Status',
      headerName: intl.formatMessage({ id: 'dataGrids.merchants.status' }),
      renderCell: (params) => <MerchantStatusCard merchant={params.row} />,
      flex: 2,
    },
    {
      field: 'CreatedAt',
      headerName: intl.formatMessage({ id: 'dataGrids.merchants.createdAt' }),
      renderCell: (params) => (
        <FormattedDate year='numeric' month='short' day='2-digit' value={params.row.CreatedAt} />
      ),
      flex: 1,
    },
    {
      field: 'UpdatedAt',
      headerName: intl.formatMessage({ id: 'dataGrids.merchants.updatedAt' }),
      renderCell: (params) => (
        <FormattedDate year='numeric' month='short' day='2-digit' value={params.row.UpdatedAt} />
      ),
      flex: 1,
    },
  ]

  return (
    <Page title={intl.formatMessage({ id: 'pages.merchantsList.title' })}>
      <Grid width='100%' container marginBottom='1.25rem'>
        <Grid item lg={3} xs={6}>
          <TextField
            placeholder='Search'
            value={search}
            onKeyUp={handleSearchKeyUp}
            onChange={(e) => setSearch(e.currentTarget.value)}
            InputProps={{ startAdornment: <FontAwesomeIcon icon={faSearch} /> }}
          />
        </Grid>
        <Grid item lg={3} />
        <Grid item xs={6} display='flex' justifyContent='flex-end'>
          <Can I={Actions.ADD} a={Subjects.MERCHANT}>
            {() => (
              <Button onClick={addNewMerchant} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                <FormattedMessage id='buttons.addNewMerchant' />
              </Button>
            )}
          </Can>
        </Grid>
        <Grid item xs={3} mt={2} ml={2}>
          {error && <Alert severity='error'>{error.message}</Alert>}
        </Grid>
      </Grid>

      <DataGrid
        height={700}
        paginationMode='server'
        page={page}
        rowCount={rowCount}
        rowsPerPageOptions={[5, 10, 20]}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        loading={fetching}
        getRowId={(merchant: MerchantSearchResult) => merchant.Id}
        rowHeight={80}
        rows={data ?? []}
        columns={columns}
      />
    </Page>
  )
}
